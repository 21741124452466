import React, { Component } from 'react'

import * as El from './styles'
import left_arrow from '../../icons/left-black.svg'
import right_arrow from '../../icons/right-black.svg'

export default class Gallery extends Component {
  state = {
    length: this.props.images.length,
    currentSlide: this.props.images[0].fluid,
    currentPosition: 0,
    changeImage: false,
    xDown: null
  }

  componentDidMount () {
    this.setGalleryInterval()
    document.addEventListener('touchstart', this.handleTouchStart.bind(this))
    document.addEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  handleTouchStart = e => {
    this.state.xDown = e.touches[0].clientX
  }

  handleTouchMove (e) {
    if (!this.state.xDown) return
    let xDiff = this.state.xDown - e.touches[0].clientX
    if (xDiff > 10) this.nextImg()
    else if (xDiff < -10) this.prevImg()
    this.state.xDown = null
  }

  componentWillUnmount () {
    this.clearGalleryInterval()
    document.removeEventListener('touchstart', this.handleTouchStart.bind(this))
    document.removeEventListener('touchmove', this.handleTouchMove.bind(this))
  }

  clearGalleryInterval = () => {
    clearInterval(this.state.changeImage)
  }

  setGalleryInterval = () => {
    this.clearGalleryInterval()
    this.state.changeImage = setInterval(this.nextImg, 5000)
  }

  setGalleryTimeout = () => {
    setTimeout(this.setGalleryInterval, 3500)
  }

  nextImg = userAction => {
    this.setState(
      {
        currentPosition:
          this.state.currentPosition < this.state.length - 1
            ? this.state.currentPosition + 1
            : 0
      },
      () => {
        if (userAction) {
          this.clearGalleryInterval()
          this.setGalleryTimeout()
        }
      }
    )
  }

  prevImg = userAction => {
    this.setState(
      {
        currentPosition:
          this.state.currentPosition === 0
            ? this.state.length - 1
            : this.state.currentPosition - 1
      },
      () => {
        if (userAction) {
          this.clearGalleryInterval()
          this.setGalleryTimeout()
        }
      }
    )
  }

  actionBar = () => (
    <El.ActionBar>
      <p>{this.props.images[this.state.currentPosition].description}</p>
      <img
        onClick={() => this.prevImg(true)}
        src={left_arrow}
        alt='previous_img'
        role='button'
      />
      <img
        onClick={() => this.nextImg(true)}
        src={right_arrow}
        alt='next_img'
        role='button'
      />
    </El.ActionBar>
  )

  inlineGallery = () => (
    <El.InlineGallery>
      {this.props.images.map((el, i) => (
        <El.Grid key={i}>
          <El.Image
            visible={this.state.currentPosition === i}
            fluid={el.fluid}
            alt={`slide_${i}`}
          />
        </El.Grid>
      ))}
    </El.InlineGallery>
  )

  render () {
    return (
      <React.Fragment>
        {this.inlineGallery(true)}

        {this.actionBar()}
      </React.Fragment>
    )
  }
}
