import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Subscriptions from '../components/Subscriptions'

export default ({ location, data, pageContext }) => (
  <Layout
    pageTitle={data.allContentfulPageSubscriptions.edges[0].node.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <Subscriptions data={data.allContentfulPageSubscriptions.edges[0].node} />
  </Layout>
)

export const subscriptionsQuery = graphql`
  query($locale: String) {
    allContentfulPageSubscriptions(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          section1 {
            childMarkdownRemark {
              html
            }
          }
          section2 {
            childMarkdownRemark {
              html
            }
          }
          section3 {
            childMarkdownRemark {
              html
            }
          }
          isGallery1Visible
          gallery1 {
            description
            fluid(maxWidth: 1280) {
              ...GatsbyContentfulFluid
            }
          }
          isGallery2Visible
          gallery2 {
            description
            fluid(maxWidth: 1280) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
