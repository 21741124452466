import Img from 'gatsby-image'
import styled from 'styled-components'
import media from '../../utils/media'

export const InlineGallery = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ${media.greaterThan('md')`
    width: 50%;
  `};
  div:nth-child(1) {
    position: relative !important;
  }
`

export const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export const Image = styled(Img)`
  z-index: 0;
  height: 100%;
  width: 100%;
  transition-duration: 0.75s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  img {
    margin: 0;
  }
`

export const ActionBar = styled.div`
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 15px 0;

  ${media.greaterThan('sm')`
    grid-template-columns: 10fr 30px 30px;
    -ms-grid-columns: 10fr 30px 30px;
    grid-template-areas: 'description arrowL arrowR';  
  `};
  ${media.lessThan('sm')`
    grid-template-columns: 30px 10fr 30px;
    -ms-grid-columns: 30px 10fr 30px;
    grid-template-areas: 'arrowL description arrowR';
    text-align: center;
    p { padding: 0 10px; }
  `};
  p {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: description;
    margin: 0;
    font-size: 15px;
    ${media.lessThan('sm')`
      font-size: 12px;
    `};
  }

  img {
    margin: 0;
    max-width: 30px;
    cursor: pointer;

    &:nth-of-type(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      grid-area: arrowL;
    }
    &:nth-of-type(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column: 3;
      grid-area: arrowR;
    }
  }
`
