import React from 'react'

import Gallery from '../Gallery'
import * as El from '../Grid/Container'

const Subscriptions = ({ data }) => (
  <El.Container>
    <div
      dangerouslySetInnerHTML={{
        __html: data.section1.childMarkdownRemark.html
      }}
    />
    {data.isGallery1Visible && <Gallery images={data.gallery1} />}
    <div
      dangerouslySetInnerHTML={{
        __html: data.section2.childMarkdownRemark.html
      }}
    />
    {data.isGallery2Visible && <Gallery images={data.gallery2} />}
    <div
      dangerouslySetInnerHTML={{
        __html: data.section3.childMarkdownRemark.html
      }}
    />
  </El.Container>
)

export default Subscriptions
