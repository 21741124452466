import styled from 'styled-components'
import media from '../../utils/media'

export const Container = styled.div`
  margin: auto;
  color: #000;
  padding: 65px 70px 20px 70px;

  ${media.lessThan('sm')`
    padding-left: 16px; 
    padding-right: 16px;
  `};

  ${media.greaterThan('md')`
    max-width: 1800px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;

    & > * { 
      width: 50%;
    }
  `};

  strong {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    white-space: nowrap;
    opacity: 0.4;
    color: #000;
  }

  p {
    margin-bottom: 28px;
    ${media.lessThan('sm')`margin-bottom:22px; `};
  }

  table {
    margin-bottom: 40px;

    ${media.lessThan('sm')`
      font-size: 16px; 
      line-height: 22px;
      tr > td:first-child {width:102px;}
    `};

    ${media.greaterThan('sm')`
      font-size: 20px; 
      line-height: 28px;
      tr > td:first-child {width:127px;}
    `};

    td,
    th {
      padding: 0;
      border: 0;
    }
  }

  br {
    opacity: 0;
  }
`
